import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'

const Block = (props) => {
	const { paragraph } = props;
        
	return (
		<Text
			dangerouslySetInnerHTML={{__html: paragraph}}  
		/>
	)
}


const Text = styled.div`
	
`

Block.wrapper = css`

`

export default Block
