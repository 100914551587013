import { postData } from "./api";
import { isClient } from "../styles/utils";
import { navigate } from "gatsby";

export const handleLogin = (cred) => {
    return new Promise((resolve, reject) => {
        postData('/pda/login', cred)
            .then(response => {
                if (response.error) {
                    resolve(response)
                } else {
                    postData('/simple-jwt-authentication/v1/token', cred)
                        .then(tokenRes => {
                            if (tokenRes.token) {
                                var dateNow = new Date();

                                localStorage.setItem('jwtToken', tokenRes.token)
                                localStorage.setItem('jwtExpiry', tokenRes.token_expires)
                                localStorage.setItem('userId', tokenRes.user_nicename)

                                resolve(response);
                            } else {
                                // Display error message
                                resolve(tokenRes);
                            }
                        })
                }
            })
            .catch(error =>  {
                console.log(error)
                reject(error)
            })   
    })   

    return false
}

export function isAuthenticated() {
    if (!isClient()) return false // return true on server

    // let auth = localStorage.getItem('auth');

    // if (auth) {
    //     return true
    // }

    let jwtToken = localStorage.getItem('jwtToken');
    let jwtExpiry = localStorage.getItem('jwtExpiry');
    var dateNow = Math.round(+ new Date()/1000);

    if (jwtToken && jwtExpiry && (parseInt(jwtExpiry) > dateNow)) {
        return true
    } else {
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('jwtExpiry');
        
        return false
    }
}



export function logout() {
    localStorage.removeItem('auth');
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('jwtExpiry');

    navigate('/')
}
