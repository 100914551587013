import React, { useState } from 'react'
import { Link, graphql, navigate } from 'gatsby'
import styled from 'styled-components'
import { Router, Location } from "@reach/router"
import { useSearchParam, useMount } from 'react-use';

import { Layout, Footer, PrivateRoute } from '../components'
import { container, padding, bgImage, hoverState } from '../styles/global'
import { fetchData } from '../services/api';

const Preview = (props) => {
	const [data, setData] = useState(null)
	const [postType, setPostType] = useState(null)
    const postID = useSearchParam('id');
    const { instanceName, relativePath} = props.pageContext;
    const Component = require(`../${instanceName}/${relativePath}`).default;

	useMount(() => {
		fetchData(`/pda/preview/${postID}`, true, true)
			.then(response => {				
				let data = response;
				setData(data)
			})
	})

	const resolveTemplate = () => {
		if (!data) return;

		const acf = data.acf?.length ? data.acf : JSON.parse(data.acf_json);

		return (
			<Component
				previewData={acf}
				pageContext={{
					data: {
						...data,
						...acf
					}
				}}
			/>
		)
	}
	
	return (
		<>
			{resolveTemplate()}
		</>
	)
}


export default Preview

