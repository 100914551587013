import React from "react"

const defaults = {
    headerVisible: true,
    indexVisible: false,
    indexActive: false,
    splashActive: true,
    setHeaderContext: () => {},
}

export const LayoutContext = React.createContext({
    ...defaults,
});

class ContextWrapper extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    setLayoutContext = (context) => {
        this.setState({
            ...this.state,
            ...context
        })
    }

    state = {
        ...defaults,
        setLayoutContext: this.setLayoutContext,
    }

	render() {
		const { children } = this.props
	
		return (
			<LayoutContext.Provider
                value={this.state}
            >
                {children}
            </LayoutContext.Provider>
		)
	}
}

export function withLayout(Component) {
    return function ThemeComponent(props) {
        return (
            <LayoutContext.Consumer>
                {context => <Component {...props} {...context} />}
            </LayoutContext.Consumer>
        )
    }
}

export default ContextWrapper