import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { sample } from 'lodash'
import { Element, scroller } from 'react-scroll';
import { useMount, useUnmount } from 'react-use'

import { Layout, Block, PostIndex } from '../components'
import { container, padding, bgImage, bgIcon, hoverState, pagePadding, pageHeading, blockPadding, pagePaddingTopIndex } from '../styles/global'
import { legacyHeading1, legacyHeading2, subheading, h3, body } from '../styles/type'
import { yellow, slateGrey, paleGrey } from '../styles/colours'
import { Zoom } from 'swiper/js/swiper.esm'
import { renderPost } from '../components/Block/templates/BlockRelatedItem'
import { media, isClient } from '../styles/utils'
import { withHeader } from '../components/Header/HeaderContext'

const Project = (props) => {
	const { data } = props.pageContext
	const [activeSlide, setActiveSlide] = useState(null)

	console.log('project data', data)

	const changeHeroSlide = (slideIndex) => {
		setActiveSlide(slideIndex + 1)

		scroller.scrollTo('hero', {
			duration: 250,
			delay: 100,
			smooth: true,
		})
	}

	const renderHero = () => {
		return (
			<Hero
				name={'hero'}
			>
				<Info>
					{data.hero_caption && (
						<Subheading>{data.hero_caption}</Subheading>
					)}
					<Heading
						as={'h1'}
						dangerouslySetInnerHTML={{__html: data.title}}
					/>
				</Info>

				<Block
					layout={'slider'}
					slides={data.hero_slider}
					sliderStyles={sliderStyles}
					activeSlide={activeSlide}
					lastBlock
					display
					controls
					breadcrumb={'Projects'}
				/>
			</Hero>
		)
	}

	const renderQuote = () => {
		if (!data || !data.collaborators_display) return;
		const { collaborators_heading, collaborators_text } = data;

		return (
			<CollabQuote>
				<Container>
					<Subheading>
						{collaborators_heading}
					</Subheading>

					<Description
						dangerouslySetInnerHTML={{__html: collaborators_text}}
					/>
				</Container>
			</CollabQuote>
		)
	}

	const renderProjectInfo = () => {
		const sector = data.sectors_primary;
		const { client, status, collaborators, year_start, year_end, other } = data.projectdata;

		const info = [
			{
				title: 'For',
				value: client.name
			},
			{
				title: 'With',
				value: collaborators
			},
			{
				title: 'Sector',
				value: sector && sector.name
			},
			{
				title: 'Status',
				value: status.name
			},
			{
				title: 'Year',
				value: `${year_start} ${year_end && year_end !== year_start ? `${year_start ? '–' : ''} ${year_end}` : ''}`
			},
			...other
		]

		const items = info.map((item, i) => {
			if (!item.value) return;

			return (
				<Item
					key={i}
				>
					<Container>
						<Heading>{item.title}</Heading>
						<Description
							dangerouslySetInnerHTML={{__html: item.value}}
						/>
					</Container>
				</Item>
			)
		})

		return (
			<ProjectInfo>
				{items}
			</ProjectInfo>
		)
	}

	const renderRelatedPosts = (data, i, type) => {
		if(!data) return

		return (
			<Item 
				as={'a'} 
				href={`/${type}/${data?.slug}`}
				key={i}
			>
				{data?.acf?.listing?.use_image_or_video !== 'video' && data?.acf?.listing?.image && (
					<Image
						key={data?.acf?.listing?.image && data?.acf?.listing?.image.sizes.medium2}
						src={data?.acf?.listing?.image && data?.acf?.listing?.image.sizes.medium2}
					>
						{(src, loading) => {
							return (
								<Img 
									src={src} 
									style={{opacity: loading ? 0 : 1}}  
								/>
							)
						}}
					</Image> 
				)}

				<Info>
					<Subheading
						dangerouslySetInnerHTML={{__html: type == 'projects' ? data?.title : data?.primary_cat.name}}
					/>

					{data?.acf?.listing?.description && (
						<Heading
							dangerouslySetInnerHTML={{__html: data?.title}}
						/>
					)}
				</Info>
			</Item>
		)
	}

	const renderRelatedStories = () => {
		if(!data?.related_stories) return

		return (
			<Block
				layout={'related'}
				heading={'Related Stories'}
			 	data={data?.related_stories}
				display={data?.display_related_stories}
				contentType={'journal'}
			/>
		)
	}

	const renderRelatedProjects = () => {
		if (!data?.related_projects) return

		return (
			<Block
				layout={'related'}
				heading={'Related Projects'}
			 	data={data?.related_projects}
				display={data?.display_related_projects}
				contentType={'projects'}
			/>
		)
	}

	const renderBlocks = () => {
        if (!data?.blocks) return;
        
		return data?.blocks.map((block, i) => { 
			return (
				<Block
					layout={block.acf_fc_layout}
					display={block.display}
					key={i}
					{...block}
				/>
			)
		})
	}

	return (
		<Layout
			meta={data.seo}
			headerBarMobile={true}
		>
			<Wrapper>
				{renderHero()}

				<Container>
					{renderBlocks()}
				</Container>

				{renderProjectInfo()}
				{renderRelatedStories()}
				{renderRelatedProjects()}
			</Wrapper>
		</Layout>
	)
}

// Shared

const Heading = styled.h2``
const Subheading = styled.h3``
const Description = styled.div``
const Info = styled.div``

const Item = styled.div``
const ItemLink = styled.a``
const Items = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const ImageWrapper = styled.div`
	position: relative;
`

const Img = styled.img`
	${bgIcon};
	transition: opacity 0.45s ease;
	background: grey;
	width: 100%;
	height: 100%;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});
	${bgImage};
	transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-x: hidden;

	${media.phone`
		// ${pagePaddingTopIndex}
		padding-top: 0px !important;
	`}
`

const Container = styled.div`
	${container}
	${padding}
	${blockPadding}

	display: flex;
	flex-direction: column;
	max-width: 100%;
`

// Hero

const Hero = styled(Element)`
	position: relative;
	margin-bottom: 160px;

	${media.tablet`
        margin-bottom: 116px;
    `}

    ${media.phone`
        margin-bottom: 80px;
    `}

	${Info} {
		display: flex;
		flex-direction: column;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 2;
		${blockPadding}
		margin-bottom: 40px;

		${media.tablet`
			margin-bottom: 20px;
		`}

		${Heading} {
			font-size: 72px;
			color: white;
			text-transform: uppercase;
			letter-spacing: 0.05em;
			font-family: 'Formula';
			line-height: 1;

			${media.phone`
				font-size: 40px;
			`}
		}

		${Subheading} {
			${body}
			color: white;
			padding-bottom: 16px;

			${media.tablet`
				padding-bottom: 10px;
			`}

			${media.tablet`
				padding-bottom: 5px;
			`}
		}
	}

	${media.phone`
		.counter {
			bottom: -48px;
			color: black;
		}
	`}
`

// Slider

const sliderStyles = css`
	height: 100vh;
	width: 100vw;

	${media.phone`
		// height: 66vw;
		height: 75vh;
	`}
`

// Fixed Heading (over slider)

const FixedHeading = styled.div`
	position: absolute;
	top: 32px;
	left: 32px;
	${subheading};
	z-index: 2;
	color: white;

	${media.phone`
		display: none;
	`}
`

// Content

const Content = styled.div`
	display: flex;
	flex-direction: column;
	${pagePadding}

	${Subheading} {
		${subheading}
	}

	> ${Subheading} {
		${pageHeading}
	}

	${Heading} {
		${legacyHeading1}
	}

	${Description} {
		margin-top: 84px;

		a {
			text-decoration: underline;
		}

		${media.phone`
			margin-top: 58px;

			&, p {
				font-size: 12px;
				line-height: 1.67;
				letter-spacing: 0.36px;
			}
		`}
	}
`

// Further Info

const FurtherShare = styled.div`
	margin-top: 50px;

	${media.phone`
		margin-top: 82px;
	`}
`

const FurtherInfo = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 41px;

	${Subheading} {
        ${subheading}
    }

	${Items} {
		display: flex;
		margin-top: 20px;

		${ItemLink} {
			height: 23px;
			background: ${yellow};
			height: 23px;
			border-radius: 6px;
			padding: 10px;

			display: flex;
			align-items: center;
			justify-content: center;
			${hoverState}

			&:not(:last-child) {
				margin-right: 16px;
			}
		}
	}

	${media.phone`
		margin-bottom: 19px;

		${Subheading} {
			font-size: 8px;
			line-height: 1.38;
		}

		${Items} {
			margin-top: 14px;

			${Item} {
				font-size: 12px;
				line-height: 1.25;
			}
		}
	`}
`

// Share

const Share = styled.div`
	display: flex;
	flex-direction: column;

	${Subheading} {
        ${subheading}
    }

	${Items} {
		display: flex;
		margin-top: 20px;

		${Item} {
			height: 23px;
			border-radius: 6px;

			display: flex;
			align-items: center;
			justify-content: center;
			${hoverState}

			&:not(:last-child) {
				margin-right: 4px;
			}
		}
	}

	${media.phone`
		${Subheading} {
			font-size: 8px;
			line-height: 1.38;
		}

		${Items} {
			margin-top: 8px;

			${Item} {
				font-size: 12px;
				line-height: 1.25;
			}
		}
	`}
`

// Collab Quote

const CollabQuote = styled.div`
    background: ${paleGrey};
    width: 100vw;
    display: flex;
	flex-direction: column;
	align-items: center;
    padding: 88px 0 70px;
    margin-top: 124px;
	margin-bottom: 100px;

    ${Subheading} {
        ${subheading}
		margin-bottom: 48px;
    }

	${Description} {
        &, p {
            ${legacyHeading2}

            a {
                ${hoverState}
            }
        }
    }

    ${media.phone`
        margin-top: 52px;
		margin-bottom: 0px;
        padding: 57px 0;

		${Subheading} {
			margin-bottom: 32px;
			font-size: 10px;
			line-height: 1.2;
		}
    `}
`


// Gallery

const Gallery = styled.div`
	margin-top: 128px;
	display: flex;
	flex-flow: row wrap;

	${Item} {
		flex: 0 1 auto;
		max-width: calc(33.3% - 64px);
		width: auto;
		height: auto;
		max-height: 192px;
		margin: 0 64px 64px 0;
		cursor: pointer;
	}

	${media.phone`
		display: none;
	`}
`

// Project Info

const ProjectInfo = styled.div`
	margin: 0 0 128px;
	width: 100%;

	${media.phone`
		${Item} {
			${Container} {
				${Heading} {
					flex: 0 1 36%;
				}
			}
		}
	`}

	${Item} {
		border-top: 1px dashed ${slateGrey};
		padding: 11px 0 9px;
		display: flex;
		justify-content: center;

		${Container} {
			display: flex;
			flex-direction: row;

			${Heading},
			${Description} {
				flex: 1;
				${subheading};
				color: ${slateGrey}
			}
		}

		&:last-child {
			border-bottom: 1px dashed ${slateGrey};
		}
	}
`

export default Project